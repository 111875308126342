/************
 * Developed by : Shiva Software Solutions
 * File Name : Dashboard.js
 * Date : 23-06-2021
 * Descriptions : Dashboard api calling
 ************/

import React from 'react';
import Api from '../../@crema/services/ApiConfig';
import {
  FETCH_ERROR,
  SHOW_MESSAGE,
  FETCH_START,
  FETCH_SUCCESS,
  GET_HC_DATA,
  DASHBOARD_PENDING_PATIENT_DATA,
  PATIENT_SUCCESS_MSG,
  ERX_SUCCESS_MSG,
  MEDICINE_SUCCESS_MSG,
  DELETE_MEDCINE_SUCCESS_MSG,
  DASHBOARD_ERX_DATA,
  GET_DOCTOR_EDITDATA,
  DASHBOARD_INSERT_PRESCRIPTION,
  DOCTOR_SUCCESS_MSG,
  DOCTOR_UPDATE_MSG,
  // REJECT_ERX_MSG,
  DASHBOARD_RX_DETAILS,
  DASHBOARD_ERX_DOC_OBSV,
  DASHBOARD_ERX_LAB_OBSV,
  DASHBOARD_ERX_PATIENT_VITAL,
  DASHBOARD_ERX_DOCPROCEDURE,
  DASHBOARD_ERX_MEDICINE_DETAIL,
  REJECT_ERX_SUCCESS_MSG,
  UNIT_DATA,
  SET_DOCTOR_ID_NAME,
  DOCTOR_SPECIALIZATION,
  DOCTOR_SUBSPECIALIZATION,
  DOCTOR_QUALIFICATION,
  DEPARTMENT_MASTER,
  STATE_MASTER,
  EXIST_COUNT,
  DOCTOR_SEARCH_ARRAY,
  DOCTOR_SEARCH_DATA,
  DASHBOARD_GRX_MEDICINE_DETAIL,
  ERX_OPERATOR_NOTES,
  resulttitle,
  CHANGE_MOBILENO_RES,
  bloodsugarList,
  ERX_IMG_ARR,
  ERX_IMG_OCR,
  REPORT_DETAILS,
  INVESTIGATION_CATEGORY_DATA,
  PATIENT_LISTS,
  TAG_CATEGORY_DATA,
  SEARCH_REPORTS_DATA,
  SEARCH_REPORTS_DATA_COUNT,
  ERX_HTML_DATA,
  ERX_HTML_NO_DATA,
  BUCKET_CAPTCHA,
  APPOINTMENT,
  APPOINTMENT_PATIENTS,
  INVESTIGATIONS_LIST,
  REPORT_PATIENT_LIST,
  REPORT_SUCCESS,
  REPORT_DELETE_SUCCESS,
  ONE_MG_DATA,
  OTP_TIME,
} from '../../shared/constants/ActionTypes';
import IntlMessages from '../../@crema/utility/IntlMessages';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {fetchError, onWriteCloudWatchLog} from './Common';
//AWS cloud watch name
var streamname = 'Redux_Actions';
var filename = 'Dashboard.js';
const crypto = require('crypto');
var algorithm = 'aes-256-ctr';
var password = 'd6F3Efeq';
export const onGetHCData = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    Api.get('/dashboard/health_care')
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_HC_DATA, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
        dispatch(
          onWriteCloudWatchLog(streamname, filename, 'onGetHCData', '0', error),
        );
      });
  };
};

//Get dashboard Pending patient information
export const onGetPendingPatInfn = ({userId, patId, userCode, erxid}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {userId, patId, userCode, erxid};
    // console.log(body, 'patient info');
    //Call Patient data Token  - Get Token
    try {
      jwtAxios
        .post('v1/patientDtls/getpatientjwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call Patient data - Get patient data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {userId, patId, userCode, erxid, jwtToken};
              jwtAxios
                .post('v1/patientDtls/getpatient', bodytoken)
                .then((data) => {
                  // console.log(data,"patient datas")
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdata = data.data.body[0];
                    dispatch({
                      type: DASHBOARD_PENDING_PATIENT_DATA,
                      payload: getdata,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetPendingPatInfn',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              console.log('error!!!!', err.response.message);
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetPendingPatInfn',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetPendingPatInfn',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      console.log('error!!!!', err.response.message);
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetPendingPatInfn',
          '0',
          err,
        ),
      );
    }
  };
};

// Update Patient details
export const onUpdatePatientDetails = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call Update Token  - Get Token
    try {
      jwtAxios
        .post('v1/patientDtls/updatejwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/patientDtls/update', value)
              .then((data) => {
                // console.log(data.data);
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});

                  dispatch({
                    type: PATIENT_SUCCESS_MSG,
                    payload: data.data.message,
                  });
                  dispatch({
                    type: SHOW_MESSAGE,
                    payload: data.data.message,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onUpdatePatientDetails',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            // console.log('error!!!!', err.response.message);
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onUpdatePatientDetails',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onUpdatePatientDetails',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      // console.log('error!!!!', err.response.message);
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onUpdatePatientDetails',
          '0',
          err,
        ),
      );
    }
  };
};

// Update eRx details
export const onUpdateeRxDetails = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call Update Token  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/updateeRxDtlsjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/pateRxMgmt/updateeRxDtls', value)
              .then((data) => {
                // console.log(data.data);
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});

                  dispatch({
                    type: ERX_SUCCESS_MSG,
                    payload: data.data.message,
                  });
                  dispatch({
                    type: SHOW_MESSAGE,
                    payload: data.data.message,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onUpdateeRxDetails',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            // console.log('error!!!!', err.response.message);
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onUpdateeRxDetails',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onUpdateeRxDetails',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      // console.log('error!!!!', err.response.message);
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onUpdateeRxDetails',
          '0',
          err,
        ),
      );
    }
  };
};

// send to Review
export const sendToReviews = (value) => {
  return (dispatch) => {
    //dispatch({type: FETCH_START});
    //Call SendToReview Token  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/sendToReviewjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/pateRxMgmt/sendtoReview', value)
              .then((data) => {
                // console.log(data.data);
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});

                  dispatch({
                    type: ERX_SUCCESS_MSG,
                    payload: data.data.message,
                  });
                  dispatch({
                    type: SHOW_MESSAGE,
                    payload: data.data.message,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'sendToReviews',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            // console.log('error!!!!', err.response.message);
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'sendToReviews',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'sendToReviews',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      // console.log('error!!!!', err.response.message);
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'sendToReviews', '0', err),
      );
    }
  };
};

// Add eRx Medicine details
export const onAddMedicineDetails = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call Update Token  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/insertMedicineData', value)
        .then((data) => {
          // console.log(data.data);
          if (data.data.status === 200) {
            dispatch({type: FETCH_SUCCESS});

            dispatch({
              type: MEDICINE_SUCCESS_MSG,
              payload: data.data.message,
            });
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onAddMedicineDetails',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      // console.log('error!!!!', err.response.message);
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onAddMedicineDetails',
          '0',
          err,
        ),
      );
    }
  };
};

// Add eRx procedure details
export const onAddProcedureDetails = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call Update Token  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/insertProcedureData', value)
        .then((data) => {
          if (data.data.status === 200) {
            dispatch({type: FETCH_SUCCESS});
            // dispatch({
            //   type: MEDICINE_SUCCESS_MSG,
            //   payload: data.data.message,
            // });
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onAddProcedureDetails',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      // console.log('error!!!!', err.response.message);
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onAddProcedureDetails',
          '0',
          err,
        ),
      );
    }
  };
};

// Delete eRx Medicine details
export const onDeleteMedicineDetails = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call Update Token  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/deleteMedicineData', value)
        .then((data) => {
          if (data.data.status === 200) {
            dispatch({type: FETCH_SUCCESS});

            dispatch({
              type: DELETE_MEDCINE_SUCCESS_MSG,
              payload: data.data.message,
            });
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onDeleteMedicineDetails',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      // console.log('error!!!!', err.response.message);
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onDeleteMedicineDetails',
          '0',
          err,
        ),
      );
    }
  };
};

// Delete eRx Procedure details
export const onDeleteProcedureDetails = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call Update Token  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/deleteProcedureData', value)
        .then((data) => {
          if (data.data.status === 200) {
            dispatch({type: FETCH_SUCCESS});

            // dispatch({
            //   type: DELETE_MEDCINE_SUCCESS_MSG,
            //   payload: data.data.message,
            // });
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onDeleteProcedureDetails',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      // console.log('error!!!!', err.response.message);
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onDeleteProcedureDetails',
          '0',
          err,
        ),
      );
    }
  };
};

// Add Reject eRx Medicine details
export const onRejecteRx = (value) => {
  // const history = useHistory();
  return (dispatch) => {
    console.log(value, 'onRejected');
    dispatch({type: FETCH_START});

    //Call Update Token  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/rejecteRxjwt', value)
        .then((data) => {
          try {
            console.log(data.data, 'Rejectedtoken');
            value['jwtToken'] = data.data.body['token'];
            // const bodytoken = [
            //   {userId: value['userId'], jwtToken: value['jwtToken'],
            //   userCode: value['userCode']},
            // ];
            jwtAxios
              .post('v1/pateRxMgmt/rejecteRx', value)
              .then((data) => {
                console.log(data.data, 'Rejected');
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});

                  dispatch({
                    type: REJECT_ERX_SUCCESS_MSG,
                    payload: data.data.message,
                  });
                  dispatch({
                    type: SHOW_MESSAGE,
                    payload: data.data.message,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onRejecteRx',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            // console.log('error!!!!', err.response.message);
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onRejecteRx',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onRejecteRx',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      // console.log('error!!!!', err.response.message);
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onRejecteRx', '0', err),
      );
    }
  };
};

// Get Unit data
export const onGetUnits = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/pateRxMgmt/getMargUomjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/pateRxMgmt/getMargUom', value)
              .then((data) => {
                // console.log(data.data);
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  var arraydata = data.data.body.map((e, i) => {
                    return {
                      title: e.unit,
                    };
                  });
                  dispatch({
                    type: UNIT_DATA,
                    payload: arraydata,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetUnits',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            // console.log('error!!!!', err.response.message);
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetUnits',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetUnits',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      // console.log('error!!!!', err.response.message);
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onGetUnits', '0', err),
      );
    }
  };
};

//Get dashboard Pending erx information
export const onGeteRxInfn = ({userId, erxReqId, userCode}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    let body = {userId, erxReqId, userCode};

    //Call Patient data Token  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/geteRxDtlsJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call Patient data - Get patient data using token
            try {
              const jwtToken = data.data.body['token'];
              let bodytoken = {userId, erxReqId, jwtToken, userCode};
              // alert(erxReqId)
              jwtAxios
                .post('v1/pateRxMgmt/geteRxDtls', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdata = data.data.body;
                    dispatch({
                      type: DASHBOARD_ERX_DATA,
                      payload: getdata,
                    });
                    if (getdata) {
                      if (getdata.erxImageOCR.length > 0) {
                        var imgurl = [];
                        var imgocr = [];
                        for (var i = 0; i < getdata.erxImageOCR.length; i++) {
                          //Push only images
                          imgurl.push({url: getdata.erxImageOCR[i].erxUrl});
                          //Push Image with ocr data and extension
                          imgocr.push({
                            url: getdata.erxImageOCR[i].erxUrl,
                            ocrdata: getdata.erxImageOCR[i].ocrData,
                            extension: getdata.erxImageOCR[i].urlExtension,
                            rownumber: getdata.erxImageOCR[i].rownumber,
                          });
                        }

                        dispatch({
                          type: ERX_IMG_ARR,
                          payload: imgurl,
                        });
                        dispatch({
                          type: ERX_IMG_OCR,
                          payload: imgocr,
                        });
                      }
                    }
                    dispatch({
                      type: GET_DOCTOR_EDITDATA,
                      payload: {},
                    });
                    // console.log(getdata,"patient erx masgnasdbh")
                    if (getdata.rxdtls.length > 0) {
                      dispatch({
                        type: DASHBOARD_RX_DETAILS,
                        payload: getdata.rxdtls[0],
                      });
                      dispatch({
                        type: SET_DOCTOR_ID_NAME,
                        payload: {
                          DocID: getdata.rxdtls[0].doctorId,
                          DocName: getdata.rxdtls[0].docName,
                          RegisterNo: getdata.rxdtls[0].registerno,
                          EmailId: getdata.rxdtls[0].email,
                        },
                      });
                    }

                    if (getdata.docobsv.length > 0) {
                      dispatch({
                        type: DASHBOARD_ERX_DOC_OBSV,
                        payload: getdata.docobsv[0],
                      });
                    }
                    if (getdata.labobsv.length > 0) {
                      dispatch({
                        type: DASHBOARD_ERX_LAB_OBSV,
                        payload: getdata.labobsv[0],
                      });
                    }

                    if (getdata.patientvital.length > 0) {
                      dispatch({
                        type: DASHBOARD_ERX_PATIENT_VITAL,
                        payload: getdata.patientvital[0],
                      });
                    }

                    if (getdata.docprocedure.length > 0) {
                      dispatch({
                        type: DASHBOARD_ERX_DOCPROCEDURE,
                        payload: getdata.docprocedure,
                      });
                    }

                    if (getdata.medicinedtls.length > 0) {
                      var arraydata = getdata.medicinedtls.map((e, i) => {
                        return {
                          saltName: e.saltName,
                          medName: e.medName,
                          mn: e.mn,
                          af: e.an,
                          en: e.en,
                          nt: e.nt,
                          unit: e.uomId,
                          unitcode: e.uomId,
                          quantity: e.dosage,
                          refferal: e.referral,
                          route: e.route,
                          advice: e.advice,
                          duration: e.duration,
                          frequency: e.frequency,
                          seqno: e.txnNo,
                        };
                      });
                      dispatch({
                        type: DASHBOARD_ERX_MEDICINE_DETAIL,
                        payload: arraydata,
                      });
                    }

                    if (getdata.genericmedicinedtls.length > 0) {
                      var arraydatageneric = getdata.genericmedicinedtls.map(
                        (e, i) => {
                          return {
                            saltName: e.saltName,
                            medName: e.medName,
                            mn: e.mn,
                            af: e.an,
                            en: e.en,
                            nt: e.nt,
                            unit: e.uomId,
                            unitcode: e.uomId,
                            quantity: e.dosage,
                            refferal: e.referral,
                            route: e.route,
                            advice: e.advice,
                            duration: e.duration,
                            durationValue: e.durationValue,
                            frequency: e.frequency,
                            seqno: e.txnNo,
                            price: e.price,
                            status: e.status,
                            mCode: e.mCode,
                            company: e.company,
                            category: e.category,
                            dosage: e.dosage,
                            qty: e.qty,
                            strength1: e.strength1,
                            strength2: e.strength2,
                            type: e.type,
                            strength: e.strength,
                            margqty: e.margqty,
                          };
                        },
                      );
                      dispatch({
                        type: DASHBOARD_GRX_MEDICINE_DETAIL,
                        payload: arraydatageneric,
                      });
                    }
                    if (getdata.rxdtls.length > 0) {
                      var patientId = getdata.rxdtls[0].patientId;

                      if (
                        getdata.rxdtls[0].doctorId !== '' &&
                        getdata.rxdtls[0].doctorId !== '0' &&
                        getdata.rxdtls[0].doctorId !== null &&
                        getdata.rxdtls[0].doctorId !== undefined
                      ) {
                        dispatch(
                          onGetDoctorInfo({
                            userId: body.userId,
                            erxId: body.erxReqId,
                            userCode: body.userCode,
                          }),
                        );
                      }
                      dispatch(
                        onGetPendingPatInfn({
                          userId: body.userId,
                          patId: patientId,
                          userCode: body.userCode,
                          erxid: body.erxReqId,
                        }),
                      );
                    }
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGeteRxInfn',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              console.log('error!!!!', err.response.message);
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGeteRxInfn',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGeteRxInfn',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      console.log('error!!!!', err.response.message);
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onGeteRxInfn', '0', err),
      );
    }
  };
};

//specialization and doctor specialization event
export const onGetSpecialization = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    var value = body;
    jwtAxios
      .post('v1/doctorDtls/specializationjwt', body)
      .then((data) => {
        try {
          body['jwtToken'] = data.data.body['token'];
          jwtAxios
            .post('v1/doctorDtls/specialization', body)
            .then((data) => {
              if (data.data.status === 200) {
                dispatch({type: FETCH_SUCCESS});

                dispatch({
                  type: DOCTOR_SPECIALIZATION,
                  payload: data.data.body.specialization,
                });
                dispatch({
                  type: DOCTOR_SUBSPECIALIZATION,
                  payload: data.data.body.subspecialization,
                });
                jwtAxios
                  .post('v1/pateRxMgmt/mastersjwt', value)
                  .then((data) => {
                    value['jwtToken'] = data.data.body['token'];
                    jwtAxios
                      .post('v1/pateRxMgmt/masters', value)
                      .then((data) => {
                        dispatch({
                          type: DEPARTMENT_MASTER,
                          payload: data.data.body.deptList,
                        });
                        // console.log(data, 'master datas');
                        dispatch({
                          type: DOCTOR_QUALIFICATION,
                          payload: data.data.body.quaList,
                        });
                        dispatch({
                          type: STATE_MASTER,
                          payload: data.data.body.stateList,
                        });
                        dispatch({
                          type: resulttitle,
                          payload: data.data.body.resulttitle,
                        });
                        dispatch({
                          type: bloodsugarList,
                          payload: data.data.body.bloodsugarList,
                        });
                        dispatch({
                          type: APPOINTMENT,
                          payload: data.data.body.appointment,
                        });
                        dispatch({
                          type: APPOINTMENT_PATIENTS,
                          payload: data.data.body.appointmentPatients,
                        });
                      })
                      .catch((error) => {
                        dispatch({type: FETCH_ERROR, payload: error.message});
                        dispatch(
                          onWriteCloudWatchLog(
                            streamname,
                            filename,
                            'onGetSpecialization',
                            '0',
                            error,
                          ),
                        );
                      });
                  })
                  .catch((error) => {
                    dispatch({type: FETCH_ERROR, payload: error.message});
                    dispatch(
                      onWriteCloudWatchLog(
                        streamname,
                        filename,
                        'onGetSpecialization',
                        '0',
                        error,
                      ),
                    );
                  });
              } else {
                dispatch({
                  type: FETCH_ERROR,
                  payload: <IntlMessages id='message.somethingWentWrong' />,
                });
              }
            })
            .catch((error) => {
              dispatch({type: FETCH_ERROR, payload: error.message});
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetSpecialization',
                  '0',
                  error,
                ),
              );
            });
        } catch (err) {
          // console.log('error!!!!', err.response.message);
          dispatch(fetchError(err.response.message));
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGeteRxInfn',
              '0',
              err,
            ),
          );
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
        dispatch(
          onWriteCloudWatchLog(
            streamname,
            filename,
            'onGetSpecialization',
            '0',
            error,
          ),
        );
      });

    //Call User list Token  - Get Token
    try {
      // console.log(value, 'edit apidVSD');
    } catch (err) {
      console.log('error!!!!', err.response.message);
      dispatch(fetchError(err.response.message));
    }
  };
};

// doctor tab seacrh doctor using mobile No/register
export const onGetSearchArray = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    // console.log(value, 'dcoitor input');
    // get jwt token based on input
    jwtAxios
      .post('v1/doctorDtls/searchDoctorRegisterJwt', value)
      .then((data) => {
        try {
          // console.log(data,"doctor edit load data jwt");
          value['jwtToken'] = data.data.body['token'];
          // get the data
          jwtAxios
            .post('v1/doctorDtls/searchDoctorRegister', value)
            .then((data) => {
              console.log(data.data.body, 'doctor search result');
              if (data.data.status === 200) {
                dispatch({type: FETCH_SUCCESS});

                dispatch({
                  type: DOCTOR_SEARCH_ARRAY,
                  payload: data.data.body,
                });
              } else {
                dispatch({
                  type: FETCH_ERROR,
                  payload: <IntlMessages id='message.somethingWentWrong' />,
                });
              }
            })
            .catch((error) => {
              dispatch({type: FETCH_ERROR, payload: error.message});
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetSearchArray',
                  '0',
                  error,
                ),
              );
            });
        } catch (err) {
          // console.log('error!!!!', err.response.message);
          dispatch(fetchError(err.response.message));
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetSearchArray',
              '0',
              err,
            ),
          );
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
        dispatch(
          onWriteCloudWatchLog(
            streamname,
            filename,
            'onGetSearchArray',
            '0',
            error,
          ),
        );
      });

    //Call User list Token  - Get Token
    try {
      // console.log(value, 'edit apidVSD');
    } catch (err) {
      console.log('error!!!!', err.response.message);
      dispatch(fetchError(err.response.message));
    }
  };
};

//search match doctor detail,s

// doctor tab seacrh doctor using mobile No/register
export const onGetSearchDoctor = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    // console.log(value, 'dcoitor input');
    // get jwt token based on input
    jwtAxios
      .post('v1/doctorDtls/searchDoctorJwt', value)
      .then((data) => {
        try {
          console.log(data, 'doctor edit load data jwt');
          value['jwtToken'] = data.data.body['token'];
          // get the data
          jwtAxios
            .post('v1/doctorDtls/searchDoctor', value)
            .then((data) => {
              console.log(data.data, 'doctor search result');

              // if (data.data.status === 200) {
              dispatch({type: FETCH_SUCCESS});
              dispatch({
                type: DOCTOR_SEARCH_DATA,
                payload: data.data.body,
              });

              // } else {
              //  console.log("enter 4 adnsaf")
              //   dispatch({
              //     type: FETCH_ERROR,
              //     payload: <IntlMessages id='message.somethingWentWrong' />,
              //   });
              // }
            })
            .catch((error) => {
              dispatch({type: FETCH_ERROR, payload: error.message});
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetSearchDoctor',
                  '0',
                  error,
                ),
              );
            });
        } catch (err) {
          // console.log('error!!!!', err.response.message);
          dispatch(fetchError(err.response.message));
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetSearchDoctor',
              '0',
              err,
            ),
          );
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
        dispatch(
          onWriteCloudWatchLog(
            streamname,
            filename,
            'onGetSearchDoctor',
            '0',
            error,
          ),
        );
      });

    //Call User list Token  - Get Token
    try {
      // console.log(value, 'edit apidVSD');
    } catch (err) {
      console.log('error!!!!', err.response.message);
      dispatch(fetchError(err.response.message));
    }
  };
};
// doctor tab edit load event
export const onGetDoctorInfo = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    // console.log(value, 'dcoitor input');
    // get jwt token based on input
    jwtAxios
      .post('v1/doctorDtls/getDoctorJwt', value)
      .then((data) => {
        try {
          // console.log(data,"doctor edit load data jwt");
          value['jwtToken'] = data.data.body['token'];
          // get the data
          jwtAxios
            .post('v1/doctorDtls/getDoctor', value)
            .then((data) => {
              // console.log(data, 'doctor result');
              if (data.data.status === 200) {
                dispatch({type: FETCH_SUCCESS});

                dispatch({
                  type: GET_DOCTOR_EDITDATA,
                  payload: data.data.body,
                });
              } else {
                dispatch({
                  type: FETCH_ERROR,
                  payload: <IntlMessages id='message.somethingWentWrong' />,
                });
              }
            })
            .catch((error) => {
              dispatch({type: FETCH_ERROR, payload: error.message});
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetDoctorInfo',
                  '0',
                  error,
                ),
              );
            });
        } catch (err) {
          // console.log('error!!!!', err.response.message);
          dispatch(fetchError(err.response.message));
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetDoctorInfo',
              '0',
              err,
            ),
          );
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
        dispatch(
          onWriteCloudWatchLog(
            streamname,
            filename,
            'onGetDoctorInfo',
            '0',
            error,
          ),
        );
      });

    //Call User list Token  - Get Token
    try {
      // console.log(value, 'edit apidVSD');
    } catch (err) {
      console.log('error!!!!', err.response.message);
      dispatch(fetchError(err.response.message));
    }
  };
};

// save doctor api
export const saveDoctorDetails = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

    //Call User list Token  - Get Token
    try {
      jwtAxios
        .post('v1/doctorDtls/createjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];

            jwtAxios
              .post('v1/doctorDtls/create', value)
              .then((data) => {
                dispatch({
                  type: SET_DOCTOR_ID_NAME,
                  payload: {
                    DocID: data.data.body.doctorId,
                    DocName: data.data.body.doctorName,
                    RegisterNo: data.data.body.registerNo,
                    EmailId: data.data.body.email,
                  },
                });
                localStorage.setItem('DocID', data.data.body.ddoctorId);
                localStorage.setItem('DocName', data.data.body.doctorName);
                dispatch({
                  type: GET_DOCTOR_EDITDATA,
                  payload: {},
                });
                dispatch({
                  type: DOCTOR_SUCCESS_MSG,
                  payload: data.data.message,
                });
                dispatch({
                  type: SHOW_MESSAGE,
                  payload: 'Doctor saved successfully',
                });
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'saveDoctorDetails',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            // console.log('error!!!!', err.response.message);
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'saveDoctorDetails',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'saveDoctorDetails',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      // console.log('error!!!!', err.response.message);
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'saveDoctorDetails',
          '0',
          err,
        ),
      );
    }
  };
};

// update doctor api
export const updateDoctorDetails = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

    //Call User list Token  - Get Token
    try {
      jwtAxios
        .post('v1/doctorDtls/updatejwt', value)
        .then((data) => {
          // console.log(data);
          try {
            value['jwtToken'] = data.data.body['token'];
            // console.log(value);
            jwtAxios
              .post('v1/doctorDtls/update', value)
              .then((data) => {
                // console.log(data.data);
                dispatch({
                  type: GET_DOCTOR_EDITDATA,
                  payload: {},
                });
                dispatch({
                  type: DOCTOR_UPDATE_MSG,
                  payload: data.data.message,
                });
                dispatch({
                  type: SHOW_MESSAGE,
                  payload: data.data.message,
                });
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'updateDoctorDetails',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            // console.log('error!!!!', err.response.message);
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'updateDoctorDetails',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'updateDoctorDetails',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      // console.log('error!!!!', err.response.message);
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'updateDoctorDetails',
          '0',
          err,
        ),
      );
    }
  };
};

//Insert and Update prescription detaails
export const onInsertPrescriptionDetails = ({userId, erxReqId, userCode}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {userId, erxReqId, userCode};
    //Call insert data
    try {
      jwtAxios
        .post('v1/pateRxMgmt/insertAssignData', body)
        .then((data) => {
          if (data.status === 200) {
            dispatch({type: FETCH_SUCCESS});
            const getdata = data.data.body;
            dispatch({
              type: DASHBOARD_INSERT_PRESCRIPTION,
              payload: getdata,
            });
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onInsertPrescriptionDetails',
              userCode,
              error,
            ),
          );
        });
    } catch (err) {
      console.log('error!!!!', err.response.message);
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onInsertPrescriptionDetails',
          userCode,
          err,
        ),
      );
    }
  };
};

//Check if already exists
export const onCheckAlreadyTakeneRx = ({userId, erxId, userCode}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {userId, erxId, userCode};
    //Call alreadytaken erx data
    try {
      jwtAxios
        .post('v1/pateRxMgmt/checkAlreadytakeneRx', body)
        .then((data) => {
          if (data.status === 200) {
            dispatch({type: FETCH_SUCCESS});
            const getdata = data.data.body;
            dispatch({
              type: EXIST_COUNT,
              payload: getdata,
            });
            dispatch({
              type: FETCH_ERROR,
              payload: 'This erx already taken',
            });
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onCheckAlreadyTakeneRx',
              userCode,
              error,
            ),
          );
        });
    } catch (err) {
      console.log('error!!!!', err.response.message);
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onCheckAlreadyTakeneRx',
          userCode,
          err,
        ),
      );
    }
  };
};

//get erxNotes
export const onGeteRxNotes = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/pateRxMgmt/getOperatorNotesjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/pateRxMgmt/getOperatorNotes', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  const getdata = data.data.body;
                  dispatch({
                    type: ERX_OPERATOR_NOTES,
                    payload: getdata,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGeteRxNotes',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGeteRxNotes',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGeteRxNotes',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onGeteRxNotes', '0', err),
      );
    }
  };
};

//Get dashboard Pending patient information
export const onChangeMobileNo = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call Patient data Token  - Get Token
    try {
      jwtAxios
        .post('v1/patMgmt/changePatMobileNojwt', value)
        .then((data) => {
          if (data.status === 200) {
            //Call Patient data - Get patient data using token
            try {
              value['jwtToken'] = data.data.body['token'];
              jwtAxios
                .post('v1/patMgmt/changePatMobileNo', value)
                .then((data) => {
                  // console.log(data,"patient datas")
                  if (data.data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdata = data.data.message;
                    dispatch({
                      type: CHANGE_MOBILENO_RES,
                      payload: getdata,
                    });
                  } else {
                    dispatch({
                      type: CHANGE_MOBILENO_RES,
                      payload: data.data.message,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetPendingPatInfn',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              console.log('error!!!!', err.response.message);
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetPendingPatInfn',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetPendingPatInfn',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      console.log('error!!!!', err.response.message);
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetPendingPatInfn',
          '0',
          err,
        ),
      );
    }
  };
};

export const onGeteRxReports = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/reportDtls/getReportDetailsjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/reportDtls/getReportDetails', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  var reports = data.data.body.map(function (e) {
                    var reportdata =
                      e.reportTagId !== null &&
                      e.reportTagId !== undefined &&
                      e.reportTagId !== 0
                        ? {uid: e.reportTagId, category: e.reportCategory}
                        : {};
                    e.reportTagId =
                      e.reportTagId !== null && e.reportTagId !== undefined
                        ? e.reportTagId
                        : 0;
                    return {...e, reportTag: reportdata};
                  });

                  dispatch({
                    type: REPORT_DETAILS,
                    payload: reports,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGeteRxReports',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGeteRxReports',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGeteRxReports',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onGeteRxReports', '0', err),
      );
    }
  };
};

// Get InvestigationCategory  data
export const onGetInvestigationCategory = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/reportDtls/searchInvestigationCaetgoryjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/reportDtls/searchInvestigationCaetgory', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  var alldata = [{uid: 0, category: 'All'}];
                  var arraydata = [...alldata, ...data.data.body];
                  dispatch({
                    type: INVESTIGATION_CATEGORY_DATA,
                    payload: data.data.body,
                  });
                  dispatch({
                    type: TAG_CATEGORY_DATA,
                    payload: arraydata,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetInvestigationCategory',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetInvestigationCategory',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetInvestigationCategory',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetInvestigationCategory',
          '0',
          err,
        ),
      );
    }
  };
};

// Get InvestigationCategory  data
export const onGetPatientList = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/reportDtls/onGetPatientListjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/reportDtls/onGetPatientList', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});

                  dispatch({
                    type: PATIENT_LISTS,
                    payload: data.data.body,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetPatientList',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetPatientList',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetPatientList',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetPatientList',
          '0',
          err,
        ),
      );
    }
  };
};

export const onGetSearchPatientReports = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/reportDtls/getSearchPatientReportsjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/reportDtls/getSearchPatientReports', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});

                  dispatch({
                    type: SEARCH_REPORTS_DATA,
                    payload: data.data.body.reportData,
                  });
                  dispatch({
                    type: SEARCH_REPORTS_DATA_COUNT,
                    payload: data.data.body.reportCount,
                  });
                  // dispatch({
                  //   type: TAG_CATEGORY_DATA,
                  //   payload: data.data.body.report_list,
                  // });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetSearchPatientReports',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetSearchPatientReports',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetSearchPatientReports',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetSearchPatientReports',
          '0',
          err,
        ),
      );
    }
  };
};

//Generate patient erx details
export const GenerateeRxHTML = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call generate html for erx
    try {
      jwtAxios
        .post('v1/pateRxMgmt/generateHTML', body)
        .then((data) => {
          if (data && data.status && data.status === 200) {
            if (data.data.body) {
              dispatch({
                type: ERX_HTML_DATA,
                payload: data.data.body,
              });
            } else {
              dispatch({
                type: ERX_HTML_NO_DATA,
                payload: 'No record found',
              });
            }
          }
        })
        .catch((error) => {
          dispatch({
            type: ERX_HTML_NO_DATA,
            payload: 'No record found',
          });
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'GenerateeRxHTML',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch({
        type: ERX_HTML_NO_DATA,
        payload: 'No record found',
      });
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'GenerateeRxHTML', '0', err),
      );
    }
  };
};

// Get ongetbucketandcaptcha  data
export const ongetbucketandcaptcha = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      // console.log("reachsnfjsdn")
      jwtAxios
        .post('v1/pateRxMgmt/ongetbucketandcaptcha')
        .then((data) => {
          if (data.data.status === 200) {
            // console.log(data.data.body)
            // dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: BUCKET_CAPTCHA,
              payload: data.data.body,
            });
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'ongetbucketandcaptcha',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'ongetbucketandcaptcha',
          '0',
          err,
        ),
      );
    }
  };
};
//Decrypt password
const decryptpassword = function ({data}) {
  var decipher = crypto.createDecipher(algorithm, password);
  var dec = decipher.update(data, 'hex', 'utf8');
  dec += decipher.final('utf8');
  return dec;
};

// Get ongetbucketandcaptcha  data
export const ongetEncryptbucketcaptcha = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/pateRxMgmt/getUploadPresCredentialsJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/pateRxMgmt/getUploadPresCredentials', value)
              .then((data) => {
                if (data.data.status === 200) {
                  let data1 = data.data.body;
                  let res = {
                    type: decryptpassword({data: data1?.type}),
                    accessKey: decryptpassword({data: data1?.accessKey}),
                    secretAccessKey: decryptpassword({
                      data: data1?.secretAccessKey,
                    }),
                    bucketName: decryptpassword({data: data1?.bucketName}),
                    region: decryptpassword({data: data1?.region}),
                    filePath: decryptpassword({data: data1?.filePath}),
                    captchaKey: decryptpassword({data: data1?.captchaKey}),
                  };
                  dispatch({
                    type: BUCKET_CAPTCHA,
                    payload: res,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              });
          } catch (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'ongetbucketandcaptcha',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'ongetbucketandcaptcha',
          '0',
          err,
        ),
      );
    }
  };
};

// Get Investigations
export const onGetInvestigations = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/patMgmt/searchInvestigationAndPatientjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/patMgmt/searchInvestigationAndPatient', value)
              .then((data) => {
                if (data.data.status === 200) {
                  if (data.data.body?.inves_lists?.length > 0) {
                    var arraydata = data.data.body.inves_lists?.map((e, i) => {
                      return {
                        title: e.description,
                        id: e.investigationid,
                        hindi_title: '',
                        category: '',
                        hindi_category: '',
                      };
                    });
                    dispatch({
                      type: INVESTIGATIONS_LIST,
                      payload: arraydata,
                    });
                  } else {
                    dispatch({
                      type: INVESTIGATIONS_LIST,
                      payload: [],
                    });
                  }
                  if (data.data.body?.patientNameLists?.length > 0) {
                    dispatch({
                      type: REPORT_PATIENT_LIST,
                      payload: data.data.body.patientNameLists,
                    });
                  } else {
                    dispatch({
                      type: REPORT_PATIENT_LIST,
                      payload: [],
                    });
                  }
                } else {
                  dispatch({
                    type: INVESTIGATIONS_LIST,
                    payload: [],
                  });
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetInvestigations',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetInvestigations',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetInvestigations',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetInvestigations',
          '0',
          err,
        ),
      );
    }
  };
};

// Insert and Upload Upload Reports
export const onInsertUploadReports = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/patMgmt/insertUploadReportsjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/patMgmt/insertUploadReports', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({
                    type: REPORT_SUCCESS,
                    payload: data.data.message,
                  });
                } else {
                  dispatch({
                    type: REPORT_SUCCESS,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetInvestigations',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetInvestigations',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetInvestigations',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetInvestigations',
          '0',
          err,
        ),
      );
    }
  };
};

// Insert and Upload Upload Reports
export const onUpdateAndDeleteUploadReports = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/patMgmt/updateandDeleteReportsJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/patMgmt/updateandDeleteReports', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({
                    type: REPORT_DELETE_SUCCESS,
                    payload: data.data.message,
                  });
                } else {
                  dispatch({
                    type: REPORT_DELETE_SUCCESS,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onUpdateAndDeleteUploadReports',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onUpdateAndDeleteUploadReports',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onUpdateAndDeleteUploadReports',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onUpdateAndDeleteUploadReports',
          '0',
          err,
        ),
      );
    }
  };
};

// Get getReportTypes  data
export const onGetReportTypes = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/reportDtls/getReportTypesJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/reportDtls/getReportTypes', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  console.log(data.data.body.report_list);
                  var alldata = [{uid: 0, category: 'All'}];
                  var arraydata = [...alldata, ...data.data.body.report_list];
                  dispatch({
                    type: INVESTIGATION_CATEGORY_DATA,
                    payload: data.data.body.report_list,
                  });
                  dispatch({
                    type: TAG_CATEGORY_DATA,
                    payload: arraydata,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetReportTypes',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetReportTypes',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetReportTypes',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetReportTypes',
          '0',
          err,
        ),
      );
    }
  };
};

// Get oneMG URL
export const onGetOneMGURL = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/pateRxMgmt/oneMgGenerateHashJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/pateRxMgmt/oneMgGenerateHash', value)
              .then((data) => {
                console.log(data);
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: ONE_MG_DATA,
                    payload: data.data,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetOneMGURL',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetOneMGURL',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetOneMGURL',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onGetOneMGURL', '0', err),
      );
    }
  };
};

export const onGetTime = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios.get('v1/pateRxMgmt/getpatientotpvalidy').then((data) => {
        if (data.status == 200) {
          dispatch({
            type: OTP_TIME,
            payload: data.data.body.description,
          });
        }
      });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onGetTime', '0', err),
      );
    }
  };
};
