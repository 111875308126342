/************
 * Developed by : Shiva Software Solutions
 * Date : 21-12-2021
 * Descriptions : Masters api calling
 ************/
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INSERT_OWN_ERX_SUCCESS,
} from '../../shared/constants/ActionTypes';
import IntlMessages from '../../@crema/utility/IntlMessages';
import React from 'react';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {fetchError, onWriteCloudWatchLog} from './Common';
//AWS cloud watch name
var streamname = 'Redux_Actions';
var filename = 'Master.js';

//insertUploadErx
export const insertUploadErx = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call procedure list Token  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/insertUploadErxjwt', body)
        .then((data) => {
          if (data.status === 200) {
            body['jwtToken'] = data.data.body['token'];
            try {
              jwtAxios
                .post('v1/pateRxMgmt/insertUploadErx', body)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({
                      type: INSERT_OWN_ERX_SUCCESS,
                      payload: 'success',
                    });
                  } else {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({
                      type: INSERT_OWN_ERX_SUCCESS,
                      payload: 'failure',
                    });
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'insertUploadErx',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'insertUploadErx',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'insertUploadErx',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'insertUploadErx', '0', err),
      );
    }
  };
};

export const s3UploadErrorEmail = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call procedure list Token  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/sendWeblinkEmailJwt', body)
        .then((data) => {
          if (data.status === 200) {
            body['jwtToken'] = data.data.body['token'];
            try {
              jwtAxios
                .post('v1/pateRxMgmt/sendWeblinkEmail', body)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                  } else {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      's3UploadErrorEmail',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  's3UploadErrorEmail',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              's3UploadErrorEmail',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          's3UploadErrorEmail',
          '0',
          err,
        ),
      );
    }
  };
};
