/************
 * Developed by : Shiva Software Solutions
 * Date : 23-06-2021
 * Descriptions : Define reducers
 ************/
import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import Settings from './Setting';
import Common from './Common';
import Dashboard from './Dashboard';
import UserManageData from './userManagement';
import Auth from './Auth';
import Patient from './Patient';
import profileReducer from './myprofile';
import CovidVitals from './CovidVitals';
import UploadeRx from './UploadeRx';
import Mapping_Patient from './MergePatient';

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    dashboard: Dashboard,
    userManage: UserManageData,
    common: Common,
    patient: Patient,
    profile: profileReducer,
    covidvitals: CovidVitals,
    UploadeRx: UploadeRx,
    Mapping_Patient: Mapping_Patient,
  });
export default reducers;
