/************
 * Developed by : Shiva Software Solutions
 * File Name : Patient.js
 * Date : 23-06-2021
 * Descriptions : Call api response using reducers
 ************/
import {
  PATIENT_COMPLETED_DATA,
  PATIENT_COMPLETED_COUNT,
  PATIENT_DASHBOARD_DATA,
  FORGOT_PASWD_SUCCESS,
  FORGOT_PASWD_ERROR,
  PATIENT_NAME_LIST,
  ERX_IMG_ARR,
  ERX_IMG_OCR,
  ERX_DATA_HTML,
  ERX_DATA_HTML_NO_DATA, PATIENT_APPOINTMENT_LIST, PATIENT_APPOINTMENT_COUNT
} from '../../shared/constants/ActionTypes';

const initialState = {
  patientlist: null,
  patientcount: null,
  forgotpaswdsuccess: '',
  forgotpaswderror: '',
  patNameList: [],
  erximgdatas: [],
  erximgocrdatas: [],
  patientvitaldashboarddata: null,
  erxhtmldata: [],
  erxhtmlnodata: '',
  patientAppointmentCount: 0,
  patientAppointments: []
};

const patientReducer = (state = initialState, action) => {
  switch (action.type) {
    //Assign user data
    case PATIENT_COMPLETED_DATA:
      return {
        ...state,
        patientlist: action.payload,
      };
    case PATIENT_COMPLETED_COUNT:
      return {
        ...state,
        patientcount: action.payload,
      };
    case FORGOT_PASWD_SUCCESS:
      return {
        ...state,
        forgotpaswdsuccess: action.payload,
      };
    case FORGOT_PASWD_ERROR:
      return {
        ...state,
        forgotpaswderror: action.payload,
      };
    case PATIENT_NAME_LIST:
      return {
        ...state,
        patNameList: action.payload,
      };
    //get image data
    case ERX_IMG_ARR:
      return {
        ...state,
        erximgdatas: action.payload,
      };
    //get erx with img data
    case ERX_IMG_OCR:
      return {
        ...state,
        erximgocrdatas: action.payload,
      };
    case PATIENT_DASHBOARD_DATA:
      return {
        ...state,
        patientvitaldashboarddata: action.payload,
      };
    case ERX_DATA_HTML:
      return {
        ...state,
        erxhtmldata: action.payload,
      };
    case ERX_DATA_HTML_NO_DATA:
      return {
        ...state,
        erxhtmlnodata: action.payload,
      };

    case PATIENT_APPOINTMENT_LIST:
      return {
        ...state,
        patientAppointments: action.payload,
      };
      case PATIENT_APPOINTMENT_COUNT:
      return {
        ...state,
        patientAppointmentCount: action.payload,
      };

    default:
      return state;
  }
};
export default patientReducer;
