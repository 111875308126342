/************
 * Developed by : Shiva Software Solutions
 * Date : 28-06-2021
 * Descriptions : To call Sigin for gudmed operators and admin design page
 ************/
export const authRole = {
  admin: ['admin'],
  user: ['Patient'],
  adminRoles: [1],
  dashboardRoles: [1],
};

export const userRole = {
  user: [
    {
      roleId: 1,
      roleName: 'Patient',
    },
  ],
};

export const defaultUser = {
  displayName: 'John Alex',
  email: 'demo@example.com',
  token: 'access-token',
  role: 'user',
  photoURL: 'https://via.placeholder.com/150',
};
export const initialUrl = '/dashboard'; // this url will open after login
export const initUrlProfile = '/my-profile'; // this url will open after login
export const MobileRegExp = /^[0-9\b]+$/;
export const digitRegExp = /^[0-9]*\.?[0-9]*$/;
export const allowDouble = /^[0-9\b.]+$/;
// export const nameRegExpdot = /[^A-Za-z\s.]/;
export const nameRegExpdot = /^\p{Lu}\p{Ll}+( \p{Lu}\p{Ll}+)*$/;
export const AvoidSpecialCharacterRegExp = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~¬£]/;
export const AvoidSpecialsearchCharacterRegExp = /[`!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?~¬£]/;
export const AvoidSpecialCharacterAddressRegExp = /[`!@#$%^*+\=\[\]{};:"\\|<>\?~¬£]/;
export const AvoidSpecialCharacterMailRegExp = /[`!#$%^&*()+\-=\[\]{};':"\\|,<>\/?~¬£]/;
export const nameRegExp = /[^A-Za-z\s]/;
export const addressRegExp = /^[A-Za-z\s][0-9]*\.?[0-9]*$/;
export const MailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
export const passwordRegExp = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/;

export const genderMaster = [
  {
    genderCode: 1,
    gender: 'Male',
  },
  {genderCode: 2, gender: 'Female'},
  {genderCode: 3, gender: 'Transgender'},
];

export const tempceltofaren = [
  {cell: 35.1, faren: 95.2},
  {cell: 35.2, faren: 95.4},
  {cell: 35.3, faren: 95.6},
  {cell: 35.4, faren: 95.8},
  {cell: 35.6, faren: 96.0},
  {cell: 35.7, faren: 96.2},
  {cell: 35.8, faren: 96.4},
  {cell: 35.9, faren: 96.6},
  {cell: 36.0, faren: 96.8},
  {cell: 36.1, faren: 97.0},
  {cell: 36.2, faren: 97.2},
  {cell: 36.3, faren: 97.4},
  {cell: 36.4, faren: 97.6},
  {cell: 36.5, faren: 97.7},
  {cell: 36.6, faren: 97.8},
  {cell: 36.7, faren: 98.0},
  {cell: 36.8, faren: 98.2},
  {cell: 36.9, faren: 98.4},
  {cell: 37.0, faren: 98.6},
  {cell: 37.1, faren: 98.8},
  {cell: 37.3, faren: 99.2},
  {cell: 37.4, faren: 99.4},
  {cell: 37.6, faren: 99.6},
  {cell: 37.7, faren: 99.8},
  {cell: 37.8, faren: 100.0},
  {cell: 37.9, faren: 100.2},
  {cell: 38.0, faren: 100.4},
  {cell: 38.1, faren: 100.6},
  {cell: 38.2, faren: 100.8},
  {cell: 38.3, faren: 101.0},
  {cell: 38.4, faren: 101.2},
  {cell: 38.6, faren: 101.4},
  {cell: 38.7, faren: 101.6},
  {cell: 38.8, faren: 101.8},
  {cell: 38.9, faren: 102.0},
  {cell: 39.0, faren: 102.2},
  {cell: 39.1, faren: 102.4},
  {cell: 39.2, faren: 102.6},
  {cell: 39.3, faren: 102.8},
  {cell: 39.4, faren: 103.0},
  {cell: 39.7, faren: 103.4},
  {cell: 39.8, faren: 103.6},
  {cell: 39.9, faren: 103.8},
  {cell: 40.0, faren: 104.0},
  {cell: 40.1, faren: 104.2},
  {cell: 40.2, faren: 104.4},
  {cell: 40.3, faren: 104.6},
  {cell: 40.4, faren: 104.8},
  {cell: 40.6, faren: 105.0},
  {cell: 40.7, faren: 105.2},
  {cell: 40.8, faren: 105.4},
  {cell: 40.9, faren: 105.6},
  {cell: 41.0, faren: 105.8},
  {cell: 41.1, faren: 106.0},
  {cell: 41.2, faren: 106.2},
  {cell: 41.3, faren: 106.4},
  {cell: 41.4, faren: 106.6},
  {cell: 41.6, faren: 106.8},
];

export const status = {
  pending: 0,
  inprogress: 1,
  rejected: 2,
  completed: 3,
  sendreview: 4,
  returnBack: 5,
};

export const englishLanguage = {
  heading: 'Add Prescription',
  firstName: 'Patient Name',
  lastName: 'Last Name',
  mobileNo: 'Mobile No.',
  email: 'Email',
  reminder: 'Reminder',
  genericMedicine: 'Generic Medicine',
  needCall: 'Need Call',
  callbackNo: 'Callback Mobile No.',
  uploadImage: 'Upload Prescription',
  uploadReport: 'Upload Reports',
  yes: 'Yes',
  no: 'No',
  save: 'Save',
  browse: 'Browse',
  errImagePdf: 'Please upload prescription is in JPEG/PNG/PDF',
  errPdfonly: 'You can upload only one pdf',
  errImageonly: 'Please upload prescription is in JPEG/PNG',
  errImages30: 'You can upload upto 30 images only',
  errPDF10: 'You can upload upto 10MB per file',
  errMobileNo: 'Please enter valid mobile number',
  errPatName: 'Please enter patient name',
  errMailId: 'Please enter a valid email',
  errPre: 'please upload prescription',
  camera: 'Camera',
  thankyou_msg:
    'Thank you for choosing GUDMED. We have received your prescription',
  thankyou_msg_callback:
    'Thank you for providing your details. A GUDMED counsellor will contact you back once you received the transcribed prescription.(within 30 minutes)',
};

export const hindiLanguage = {
  heading: 'दवा का पर्चा भेजें',
  firstName: 'मरीज का नाम',
  lastName: 'अंतिम नाम',
  mobileNo: 'मोबाइल नंबर.',
  email: 'ईमेल',
  reminder: 'रिमाइंडर',
  genericMedicine: 'जेनेरिक दवा',
  needCall: 'कॉल की आवश्यकता है',
  callbackNo: 'Callback Mobile No.',
  uploadImage: 'दवा के पर्चे की फोटो भेजें',
  uploadReport: 'रिपोर्ट की फोटो भेजें',
  yes: 'हाँ',
  no: 'नहीं',
  save: 'सेव करें',
  browse: 'ब्राउज',
  errImagePdf: 'कृपया अपने दवा का परचा निम्न प्रारूप में डालें JPEG/PNG/PDF',
  errPdfonly: 'आप सिर्फ एक PDF भेज सकते हैं',
  errImageonly: 'कृपया अपने दवा का परचा निम्न प्रारूप में डालें JPEG/PNG',
  errImages30: 'आप सिर्फ पर्चे की 30 फोटो भेज सकते हैं',
  errPDF10: 'अधिकतम अपलोड फ़ाइल का आकार 10MB है',
  errMobileNo: 'कृपया सही मोबाइल नंबर डालें',
  errPatName: 'कृपया मरीज का नाम दर्ज करें',
  errMailId: 'कृपया सही ईमेल डालें',
  errPre: 'कृपया प्रिस्क्रिप्शन अपलोड करें',
  camera: 'कैमरा',
  thankyou_msg:
    'हमारी सेवा का लाभ उठाने के लिए धन्यवाद।  हमें आपके दवा का पर्चा मिल गया है।',
  thankyou_msg_callback:
    'दवा के पर्चे का डिजिटल रूपांतरण आपको मिलने के बाद, गुडमेड के प्रतिनिधि आपको 30 मिनट में कॉल करेंगे।',
};
export const GoogleRecaptchaKey = '6LfpP1cfAAAAAMpzEcBXGC2CyFhlpfC90cBbD2RA';
// export const GoogleRecaptchaKey = '6LfH1p4eAAAAAGbTKDroui1_WGKT_T4__PnuZgu7';

export const version = '1.0.5';

export const clientID = {
  gudmed: 1,
  redcliffee: 2,
  aiims: 3,
  privatedoctors: 4,
};
export const mediaId = {
  demoWebLink: '10',
};
