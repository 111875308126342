import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
  TOGGLE_APP_DRAWER,
  UPDATING_CONTENT,
} from '../../shared/constants/ActionTypes';
//Cloud watch logs require functionality
var winston = require('winston'),
  WinstonCloudWatch = require('winston-cloudwatch');
var AWS = require('aws-sdk');
const SECRETKEY = 'AKIAZH552SF5X5JDFU6J';
const ACCESSKEY = 'sxmQcF8Ba7q8mbBfPxhQS4vqX3x7OGy+nmt3bZPB';
const REGION = 'ap-south-1';
const GROUPNAME = 'gudmedweblog';

export const fetchStart = () => {
  return (dispatch) => dispatch({type: FETCH_START});
};

export const fetchSuccess = () => {
  return (dispatch) => dispatch({type: FETCH_SUCCESS});
};
export const updatingContent = () => {
  return (dispatch) => dispatch({type: UPDATING_CONTENT});
};

export const fetchError = (error) => {
  return (dispatch) => dispatch({type: FETCH_ERROR, payload: error});
};

export const showMessage = (message) => {
  return (dispatch) => dispatch({type: SHOW_MESSAGE, payload: message});
};
export const onToggleAppDrawer = () => {
  return (dispatch) => dispatch({type: TOGGLE_APP_DRAWER});
};

export const hideMessage = () => {
  return (dispatch) => dispatch({type: HIDE_MESSAGE});
};

export const onWriteCloudWatchLog = (
  streamName,
  filename,
  functionname,
  usercode,
  error,
) => {
  return async (dispatch) => {
    var getdate = new Date();
    //AWS config file declaration
    AWS.config.update({
      region: REGION,
      accessKeyId: SECRETKEY,
      secretAccessKey: ACCESSKEY,
    });
    //Winston configuration file
    winston.add(
      new WinstonCloudWatch({
        logGroupName: GROUPNAME,
        logStreamName: streamName + '_Web',
        awsRegion: REGION,
        awsAccessKeyId: SECRETKEY,
        awsSecretKeyId: ACCESSKEY,
      }),
    );
    //Write error in cloud watch
    winston.error(
      'WEB - File Name : ' +
        filename +
        ' , Function : ' +
        functionname +
        ' , UserCode : ' +
        usercode +
        ' , Error :' +
        error +
        ' , DateTime : ' +
        getdate,
    );
  };
};
