import {
  SET_AUTH_TOKEN,
  SIGNOUT_AUTH_SUCCESS,PATIENT_SIGNUP,OTP_SUCCESS,OTP_ERROR,
  UPDATE_AUTH_USER,SET_MOBILENO,SET_PASWD,OTP_SIGNUP_ERROR,SIGNIN_ERROR,
} from '../../shared/constants/ActionTypes';

const INIT_STATE = {
  user: null,
  token: null,
  mobileno: null,
  paswd: null,
  signupstatus: '',
  otpsuccess:false,
  otperror:'',
  otpsignuperror: '',
  signinerror:'',
};

const authReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_AUTH_USER: {
      return {
        ...state,
        user: action.payload,
      };
    }
    case SIGNOUT_AUTH_SUCCESS: {
      return {
        ...state,
        user: null,
      };
    }
    case SET_AUTH_TOKEN: {
      return {
        ...state,
        token: action.payload,
      };
    }
    case SET_MOBILENO: {
      return {
        ...state,
        mobileno: action.payload,
      };
    }
    case SET_PASWD: {
      return {
        ...state,
        paswd: action.payload,
      };
    }
    case PATIENT_SIGNUP: {
      return {
        ...state,
        signupstatus: action.payload,
      };
    }
    case OTP_SUCCESS: {
      return {
        ...state,
        otpsuccess: action.payload,
      };
    }
    case OTP_SIGNUP_ERROR: {
      return {
        ...state,
        otpsignuperror: action.payload,
      };
    }
    case SIGNIN_ERROR: 
    { 
      return { 
        ...state, 
        signinerror: action.payload, 
      }; 
    }
    case OTP_ERROR: 
    { 
      return { 
        ...state, 
        otperror: action.payload, 
      }; 
    }
    default:
      return state;
  }
};
export default authReducer;
