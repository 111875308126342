/************
 * Developed by : Shiva Software Solutions
 * Date : 30-03-2022
 * Descriptions : Route for report page
 ************/
import React from 'react';
import {authRole} from '../../shared/constants/AppConst';

export const reportsConfig = [
  //Redirect to dashboard page
  {
    auth: authRole.user,
    routes: [
      {
        path: '/reports',
        component: React.lazy(() => import('./reportPage/index')),
      },
    ],
  },
];
