/************
 * Developed by : Shiva Software Solutions
 * Date : 14-06-2021
 * Descriptions : Route page for signup
 ************/
import React from 'react';

export const authRouteConfig = [
  {
    routes: [
      {
        path: '/login',
        component: React.lazy(() => import('./Signin/index')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/signin',
        component: React.lazy(() => import('./SigninWithOTP/index')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/login1',
        component: React.lazy(() => import('./Signin/SigninPatient')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/signup',
        component: React.lazy(() => import('./Signup/index')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/forget-password',
        component: React.lazy(() => import('./ForgetPassword')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/terms-conditions',
        component: React.lazy(() => import('./Signup/Termsandconditions')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/error-pages/error-404',
        component: React.lazy(() => import('../errorPages/Error404/index')),
      },
    ],
  },
];
