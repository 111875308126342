/************
 * Developed by : Shiva Software Solutions
 * Date : 23-06-2021
 * Descriptions : Dashboard api calling
 ************/

import React from 'react';
import {
  FETCH_ERROR,
  FETCH_START,
  PATIENT_COMPLETED_DATA,
  PATIENT_COMPLETED_COUNT,
  FORGOT_PASWD_SUCCESS,
  FORGOT_PASWD_ERROR,
  FETCH_SUCCESS,
  PATIENT_NAME_LIST,
  ERX_IMG_ARR,
  ERX_IMG_OCR,
  ERX_DATA_HTML,
  ERX_DATA_HTML_NO_DATA,
  PATIENT_APPOINTMENT_LIST,
  PATIENT_APPOINTMENT_COUNT,
} from '../../shared/constants/ActionTypes';
import IntlMessages from '../../@crema/utility/IntlMessages';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {fetchError, onWriteCloudWatchLog} from './Common';
//AWS cloud watch name
var streamname = 'Redux_Actions';
var filename = 'Patient.js';

//Get Patient completed List data
export const onGetPatientCompletedList = ({
  mobileNbr,
  patId,
  limit,
  offset,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {
      mobileNbr,
      patId,
      limit,
      offset,
    };
    try {
      jwtAxios
        .post('v1/patMgmt/patientCompletedListjwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call pending list data - Get pending list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {
                mobileNbr,
                patId,
                limit,
                offset,
                jwtToken,
              };
              jwtAxios
                .post('v1/patMgmt/patientCompletedList', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    console.log(data, 'CompletedList');
                    dispatch({type: FETCH_SUCCESS});
                    const getdata = data.data.body;
                    dispatch({
                      type: PATIENT_COMPLETED_COUNT,
                      payload: data.data.body.totalcount,
                    });
                    dispatch({
                      type: PATIENT_COMPLETED_DATA,
                      payload: data.data.body.data,
                    });
                    dispatch({
                      type: PATIENT_NAME_LIST,
                      payload: data.data.body.patNameList,
                    });
                    if (getdata) {
                      if (getdata.erxImageOCR.length > 0) {
                        var imgurl = [];
                        var imgocr = [];
                        for (var i = 0; i < getdata.erxImageOCR.length; i++) {
                          //Push only images
                          imgurl.push({url: getdata.erxImageOCR[i].erxUrl});
                          //Push Image with ocr data and extension
                          imgocr.push({
                            url: getdata.erxImageOCR[i].erxUrl,
                            erxid: getdata.erxImageOCR[i].erxId,
                            extension: getdata.erxImageOCR[i].urlExtension,
                            rownumber: getdata.erxImageOCR[i].rownumber,
                          });
                        }

                        dispatch({
                          type: ERX_IMG_ARR,
                          payload: imgurl,
                        });
                        dispatch({
                          type: ERX_IMG_OCR,
                          payload: imgocr,
                        });
                      }
                    }
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetPatientCompletedList',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              console.log('error!!!!', err.response.message);
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetPatientCompletedList',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetPatientCompletedList',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      console.log('error!!!!', err.response.message);
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetPatientCompletedList',
          '0',
          err,
        ),
      );
    }
  };
};

//Sign up Patient
export const onSignupPatient = ({mobileNbr, pName, limit, offset}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {
      mobileNbr,
      pName,
      limit,
      offset,
    };
    try {
      jwtAxios
        .post('v1/patMgmt/patientCompletedListjwt', body)
        .then((data) => {
          // console.log(data)
          if (data.status === 200) {
            //Call pending list data - Get pending list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {
                mobileNbr,
                pName,
                limit,
                offset,
                jwtToken,
              };
              jwtAxios
                .post('v1/patMgmt/patientCompletedList', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdata = data.data.body.data;
                    dispatch({
                      type: PATIENT_COMPLETED_COUNT,
                      payload: data.data.body.totalcount,
                    });
                    dispatch({
                      type: PATIENT_COMPLETED_DATA,
                      payload: getdata,
                    });
                    dispatch({
                      type: PATIENT_NAME_LIST,
                      payload: data.data.body.patNameList,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onSignupPatient',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              console.log('error!!!!', err.response.message);
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onSignupPatient',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onSignupPatient',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      console.log('error!!!!', err.response.message);
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onSignupPatient', '0', err),
      );
    }
  };
};

//forget password
export const onForgetPassword = ({mobileNbr, newpaswd, otp}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {
      mobileNbr,
      newpaswd,
      otp,
    };
    try {
      jwtAxios
        .post('v1/patMgmt/forgetpaswdjwt', body)
        .then((data) => {
          // console.log(data)
          if (data.status === 200) {
            //Call pending list data - Get pending list data using token
            try {
              if (data.hasOwnProperty.call(data.data, 'body')) {
                const jwtToken = data.data.body['token'];
                const bodytoken = {
                  mobileNbr,
                  newpaswd,
                  otp,
                  jwtToken,
                };
                jwtAxios
                  .post('v1/patMgmt/forgetpaswd', bodytoken)
                  .then((data) => {
                    if (data.status === 200) {
                      // dispatch({type: FETCH_SUCCESS});
                      dispatch({
                        type: FORGOT_PASWD_SUCCESS,
                        payload: 'Your password has been changed successfully',
                      });
                    } else {
                      if (data.data.message.includes('1')) {
                        dispatch({
                          type: FORGOT_PASWD_ERROR,
                          payload: 'Invalid OTP',
                        });
                      }
                      if (data.data.message.includes('2')) {
                        dispatch({
                          type: FORGOT_PASWD_ERROR,
                          payload: 'Your OTP is expired , Please try again!',
                        });
                      }
                    }
                  })
                  .catch((error) => {
                    dispatch({type: FETCH_ERROR, payload: error.message});
                    dispatch(
                      onWriteCloudWatchLog(
                        streamname,
                        filename,
                        'onForgetPassword',
                        '0',
                        error,
                      ),
                    );
                  });
              } else {
                if (data.data.hasOwnProperty.call(data.data, 'message')) {
                  if (data.data.message.includes('Error')) {
                    if (data.data.message.includes('2')) {
                      dispatch({
                        type: FORGOT_PASWD_ERROR,
                        payload: data.data.message.split('2')[1],
                      });
                    } else if (data.data.message.includes('1')) {
                      dispatch({
                        type: FORGOT_PASWD_ERROR,
                        payload: data.data.message.split('1')[1],
                      });
                    } else if (data.data.message.includes('3')) {
                      dispatch({
                        type: FORGOT_PASWD_ERROR,
                        payload: data.data.message.split('3')[1],
                      });
                    } else {
                      dispatch({
                        type: FORGOT_PASWD_ERROR,
                        payload: 'Invalid OTP',
                      });
                    }
                  }
                }
              }
            } catch (err) {
              console.log('error!!!!', err.response.message);
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onForgetPassword',
                  '0',
                  err,
                ),
              );
            }
          } else {
            if (data.hasOwnProperty.call(data, 'message')) {
              if (data.message.includes('Error')) {
                if (data.message.includes('2')) {
                  dispatch({
                    type: FORGOT_PASWD_ERROR,
                    payload: data.message.split('2')[1],
                  });
                } else if (data.message.includes('1')) {
                  dispatch({
                    type: FORGOT_PASWD_ERROR,
                    payload: data.message.split('1')[1],
                  });
                } else if (data.data.message.includes('3')) {
                  dispatch({
                    type: FORGOT_PASWD_ERROR,
                    payload: data.data.message.split('3')[1],
                  });
                } else {
                  dispatch({
                    type: FORGOT_PASWD_ERROR,
                    payload: 'Invalid OTP',
                  });
                }
              }
            }
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onForgetPassword',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      console.log('error!!!!', err.response.message);
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onForgetPassword',
          '0',
          err,
        ),
      );
    }
  };
};

//get Generate erx html
export const GenerateERXHTML = (body) => {
  return (dispatch) => {
    dispatch({
      type: ERX_DATA_HTML,
      payload: [],
    });
    dispatch({type: FETCH_START});
    //Call symptom list Token  - Get Token
    try {
      //Call symptom User list data - Get symptom list data using token
      try {
        jwtAxios
          .post('v1/pateRxMgmt/generateHTML', body)
          .then((data) => {
            if (data && data.status && data.status === 200) {
              if (data.data.body) {
                dispatch({
                  type: ERX_DATA_HTML_NO_DATA,
                  payload: '',
                });
                dispatch({
                  type: ERX_DATA_HTML,
                  payload: data.data.body,
                });
              } else {
                dispatch({
                  type: ERX_DATA_HTML_NO_DATA,
                  payload: 'No record found',
                });
              }
            }
          })
          .catch((error) => {
            dispatch({
              type: ERX_DATA_HTML_NO_DATA,
              payload: 'No record found',
            });
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'GetCovidVitalsListData',
                '0',
                error,
              ),
            );
          });
      } catch (err) {
        dispatch({
          type: ERX_DATA_HTML_NO_DATA,
          payload: 'No record found',
        });
        dispatch(
          onWriteCloudWatchLog(
            streamname,
            filename,
            'GetCovidVitalsListData',
            '0',
            err,
          ),
        );
      }
    } catch (err) {
      dispatch({
        type: ERX_DATA_HTML_NO_DATA,
        payload: 'No record found',
      });
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'GetCovidVitalsListData',
          '0',
          err,
        ),
      );
    }
  };
};

//getAppointmentsJWt
export const onGetAppointments = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/pateRxMgmt/getAppointmentsJwt', value)
        .then((data) => {
          // console.log(data)
          if (data.status === 200) {
            //Call pending list data - Get pending list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = value;
              bodytoken['jwtToken'] = jwtToken;
              jwtAxios
                .post('v1/pateRxMgmt/getAppointments', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdata = data.data.body.data;
                    dispatch({
                      type: PATIENT_APPOINTMENT_COUNT,
                      payload: data.data.body.totalCount,
                    });
                    dispatch({
                      type: PATIENT_APPOINTMENT_LIST,
                      payload: getdata,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetAppointments',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              console.log('error!!!!', err.response.message);
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetAppointments',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetAppointments',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      console.log('error!!!!', err.response.message);
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetAppointments',
          '0',
          err,
        ),
      );
    }
  };
};
