/************
 * Developed by : Shiva Software Solutions
 * File Name : userManagement.js
 * Date : 23-06-2021
 * Descriptions : Call api response using reducers
 ************/
import {
  GET_USERMANAGE_DATA,
  DELETE_USER_DATA,
  GET_GUDMEDSUCCESS_DATA,
  GET_GUDMED_USER,
  RESET_USER_PASWD,
} from '../../shared/constants/ActionTypes';

const initialState = {
  users: [],
  deleteuserdata: null,
  message: '',
  editloaddata: {},
  resetpaswd: '',
};

const userManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    //Assign user data
    case GET_USERMANAGE_DATA:
      return {
        ...state,
        users: action.payload,
      };
    //Assign Get user  success data
    case GET_GUDMEDSUCCESS_DATA:
      return {
        ...state,
        message: action.payload,
      };
    //Assign Get user data
    case GET_GUDMED_USER:
      return {
        ...state,
        editloaddata: action.payload,
      };
    //Assign user delete response
    case DELETE_USER_DATA:
      return {
        ...state,
        deleteuserdata: action.payload,
      };
    //Assign success reset paswd
    case RESET_USER_PASWD:
      return {
        ...state,
        resetpaswd: action.payload,
      };

    default:
      return state;
  }
};
export default userManagementReducer;
