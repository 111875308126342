/************
 * Developed by : Shiva Software Solutions
 * Date : 23-06-2021
 * Descriptions : Dashboard api calling
 ************/

import React from 'react';
import {
  FETCH_ERROR,
  FETCH_START,
  PATIENT_VITAL_HISTORY_DATA,
  PATIENT_VITAL_HISTORY_DATA_COUNT,
  FETCH_SUCCESS,
  VITAL_DATA_NOT_FOUND,
  VITAL_PDF_DATA,
  PATIENT_VITAL_PATIENTS,
  COVID_DATA_HTML,
  COVID_DATA_HTML_NO_DATA,
} from '../../shared/constants/ActionTypes';
import IntlMessages from '../../@crema/utility/IntlMessages';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {fetchError, onWriteCloudWatchLog} from './Common';
//AWS cloud watch name
var streamname = 'Redux_Actions';
var filename = 'Patient.js';
//Get Patient covid  List data
export const onGetPatientVitalList = ({
  userId,
  userCode,
  limit,
  offset,
  mobileNo,
  filterfromDate,
  filtertoDate,
  covidStatus,
  patName,
  vitalarr,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {
      userId,
      userCode,
      limit,
      offset,
      mobileNo,
      filterfromDate,
      filtertoDate,
      covidStatus,
      patName,
    };
    try {
      jwtAxios
        .post('v1/covid/getCovidPatientListjwt', body)
        .then((data) => {
          // console.log(data)
          if (data.status === 200) {
            //Call pending list data - Get pending list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {
                userId,
                userCode,
                limit,
                offset,
                mobileNo,
                filterfromDate,
                filtertoDate,
                covidStatus,
                patName,
                jwtToken,
              };
              jwtAxios
                .post('v1/covid/getCovidPatientList', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    // console.log(data, 'CompletedList');
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({
                      type: PATIENT_VITAL_HISTORY_DATA_COUNT,
                      payload: data.data.body.totalCount,
                    });
                    dispatch({
                      type: PATIENT_VITAL_HISTORY_DATA,
                      payload: [],
                    });
                    dispatch({
                      type: PATIENT_VITAL_HISTORY_DATA,
                      payload: [...vitalarr, ...data.data.body.resultData],
                    });

                    dispatch({
                      type: PATIENT_VITAL_PATIENTS,
                      payload: data.data.body.patientlistdatas,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetPatientVitalList',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              console.log('error!!!!', err.response.message);
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetPatientVitalList',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetPatientVitalList',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      console.log('error!!!!', err.response.message);
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetPatientVitalList',
          '0',
          err,
        ),
      );
    }
  };
};

//getcovid vitals  list using jwt token
export const GenerateVitalPDF = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call symptom list Token  - Get Token
    try {
      jwtAxios
        .post('v1/covid/generateVitalPdfjwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call symptom User list data - Get symptom list data using token
            try {
              body['jwtToken'] = data.data.body['token'];

              jwtAxios
                .post('v1/covid/generateVitalPdf', body)
                .then((data) => {
                  if (data && data.status && data.status === 200) {
                    if (data.data.body) {
                      dispatch({
                        type: VITAL_PDF_DATA,
                        payload: data.data.body,
                      });
                    } else {
                      dispatch({
                        type: VITAL_DATA_NOT_FOUND,
                        payload: 'No record found',
                      });
                    }
                  }
                })
                .catch((error) => {
                  dispatch({
                    type: VITAL_DATA_NOT_FOUND,
                    payload: 'No record found',
                  });
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'GetCovidVitalsListData',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch({
                type: VITAL_DATA_NOT_FOUND,
                payload: 'No record found',
              });
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'GetCovidVitalsListData',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({
            type: VITAL_DATA_NOT_FOUND,
            payload: 'No record found',
          });
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'GetCovidVitalsListData',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch({
        type: VITAL_DATA_NOT_FOUND,
        payload: 'No record found',
      });
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'GetCovidVitalsListData',
          '0',
          err,
        ),
      );
    }
  };
};

//get covid vitals list using jwt token
export const GenerateVitalHTML = (body) => {
  return (dispatch) => {
    dispatch({
      type: COVID_DATA_HTML,
      payload: [],
    });
    dispatch({type: FETCH_START});
    //Call symptom list Token  - Get Token
    try {
      jwtAxios
        .post('v1/covid/generateVitalHTMLjwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call symptom User list data - Get symptom list data using token
            try {
              body['jwtToken'] = data.data.body['token'];

              jwtAxios
                .post('v1/covid/generateVitalHTML', body)
                .then((data) => {
                  if (data && data.status && data.status === 200) {
                    if (data.data.body) {
                      dispatch({
                        type: COVID_DATA_HTML_NO_DATA,
                        payload: '',
                      });
                      dispatch({
                        type: COVID_DATA_HTML,
                        payload: data.data.body,
                      });
                    } else {
                      dispatch({
                        type: COVID_DATA_HTML_NO_DATA,
                        payload: 'No record found',
                      });
                    }
                  }
                })
                .catch((error) => {
                  dispatch({
                    type: COVID_DATA_HTML_NO_DATA,
                    payload: 'No record found',
                  });
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'GetCovidVitalsListData',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch({
                type: COVID_DATA_HTML_NO_DATA,
                payload: 'No record found',
              });
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'GetCovidVitalsListData',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({
            type: COVID_DATA_HTML_NO_DATA,
            payload: 'No record found',
          });
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'GetCovidVitalsListData',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch({
        type: COVID_DATA_HTML_NO_DATA,
        payload: 'No record found',
      });
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'GetCovidVitalsListData',
          '0',
          err,
        ),
      );
    }
  };
};
