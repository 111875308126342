/************
 * Developed by : Shiva Software Solutions
 * File Name : Dashboard.js
 * Date : 23-06-2021
 * Descriptions : Call api response using reducers
 ************/
import {
  GET_HC_DATA,
  DASHBOARD_PENDING_DATA,
  DASHBOARD_PENDING_COUNT,
  DASHBOARD_PENDING_PATIENT_DATA,
  PATIENT_SUCCESS_MSG,
  ERX_SUCCESS_MSG,
  DASHBOARD_ERX_DATA,
  GET_DOCTOR_EDITDATA,
  DASHBOARD_INSERT_PRESCRIPTION,
  DASHBOARD_INPROGRESSS_DATA,
  DASHBOARD_INPROGRESSS_COUNT,
  DOCTOR_SUCCESS_MSG,
  REJECT_ERX_MSG,
  DOCTOR_UPDATE_MSG,
  MEDICINE_SUCCESS_MSG,
  DASHBOARD_RX_DETAILS,
  DASHBOARD_ERX_DOC_OBSV,
  DASHBOARD_ERX_LAB_OBSV,
  DASHBOARD_ERX_PATIENT_VITAL,
  DASHBOARD_ERX_DOCPROCEDURE,
  DASHBOARD_ERX_MEDICINE_DETAIL,
  DASHBOARD_REJECT_DATA,
  DASHBOARD_REJECT_COUNT,
  REJECT_ERX_SUCCESS_MSG,
  DASHBOARD_COMPLETED_COUNT,
  UNIT_DATA,
  DASHBOARD_COMPLETED_DATA,
  DELETE_MEDCINE_SUCCESS_MSG,
  SET_DOCTOR_ID_NAME,
  DOCTOR_SPECIALIZATION,
  DOCTOR_SUBSPECIALIZATION,
  DOCTOR_QUALIFICATION,
  DEPARTMENT_MASTER,
  STATE_MASTER,
  OPT_NOTES,
  DOCTOR_SEARCH_ARRAY,
  ERX_IMG_ARR,
  ERX_IMG_OCR,
  DOCTOR_SEARCH_DATA,
  ERX_OPERATOR_NOTES,
  resulttitle,
  CHANGE_MOBILENO_RES,
  bloodsugarList,
  DASHBOARD_GRX_MEDICINE_DETAIL,
  REPORT_DETAILS,
  INVESTIGATION_CATEGORY_DATA,
  PATIENT_LISTS,
  TAG_CATEGORY_DATA,
  SEARCH_REPORTS_DATA,
  SEARCH_REPORTS_DATA_COUNT,
  ERX_HTML_DATA,
  ERX_HTML_NO_DATA,
  BUCKET_CAPTCHA,
  APPOINTMENT,
  APPOINTMENT_PATIENTS,
  INVESTIGATIONS_LIST,
  REPORT_PATIENT_LIST,
  REPORT_SUCCESS,
  REPORT_DELETE_SUCCESS,
  ONE_MG_DATA,
  OTP_TIME,
} from '../../shared/constants/ActionTypes';

const initialState = {
  healthCare: null,
  dashboardPendingList: {},
  pendingCount: 0,
  dashboardPendingPatientData: {},
  patientsuccessmsg: null,
  erxsuccessmsg: null,
  rejecterxsuccessmsg: null,
  erxmedicinesuccessmsg: null,
  dashboarderxData: {},
  dashboarderxDetails: {},
  dashboarderxDocobsv: {},
  dashboarderxLabobsv: {},
  dashboarderxPatientVital: {},
  dashboarderxDocProcedure: [],
  dashboarderxmedicinedetail: {},
  doctorDetails: {},
  otptime: '',
  dashboardinsertprescriptions: {},
  inprogresCount: 0,
  dashboardInprogressList: {},
  dashboardRejectList: {},
  dashboardRejectcountList: null,
  doctorsuccessmsg: null,
  doctorupdatemsg: null,
  rejectsuccessmsg: null,
  dashboardCompletedList: {},
  dashboardCompletedcountList: null,
  unitdata: [],
  deletemedicinesuccessmsg: null,
  doctoridname: {},
  specializations: [],
  subspecializations: [],
  qualifications: [],
  departmentMaster: [],
  stateMaster: [],
  optNotes: '',
  doctorSearchDatas: [],
  searchableDoctor: {},
  erxOperatorNotes: [],
  resulttitle: {},
  changemobilenores: '',
  bloodsugarList: {},
  erximgdatas: [],
  erximgocrdatas: [],
  dashboardgrxmedicinedetail: {},
  reportDetails: [],
  investigationCategoryData: [],
  patientListdata: [],
  tagCategoryData: [],
  searchReportsData: [],
  searchReportsDataCounts: 0,
  erxhtmldata: {},
  erxhtmlnodata: '',
  bucketCaptcha: {},
  appointment: '',
  appointmentPatients: [],
  inves_list: [],
  patient_list: [],
  report_success: false,
  report_delete_success: '',
  oneMgData: '',
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HC_DATA:
      return {
        ...state,
        healthCare: action.payload,
      };
    case OPT_NOTES:
      return {
        ...state,
        optNotes: action.payload,
      };

    //Assign dashboard pending data
    case DASHBOARD_PENDING_DATA:
      return {
        ...state,
        dashboardPendingList: action.payload,
      };
    //Assign dashboard pending count
    case DASHBOARD_PENDING_COUNT:
      return {
        ...state,
        pendingCount: action.payload,
      };
    //Assign dashboard pending data
    case DASHBOARD_REJECT_DATA:
      return {
        ...state,
        dashboardRejectList: action.payload,
      };
    //Assign dashboard pending count
    case DASHBOARD_REJECT_COUNT:
      return {
        ...state,
        dashboardRejectcountList: action.payload,
      };
    //Assign dashboard pending Patient data
    case DASHBOARD_PENDING_PATIENT_DATA:
      return {
        ...state,
        dashboardPendingPatientData: action.payload,
      };
    //Assign dashboard pending Patient success msg
    case PATIENT_SUCCESS_MSG:
      return {
        ...state,
        patientsuccessmsg: action.payload,
      };
    //Assign successmsg
    case ERX_SUCCESS_MSG:
      return {
        ...state,
        erxsuccessmsg: action.payload,
      };
    //Assign successmsg
    case REJECT_ERX_MSG:
      return {
        ...state,
        rejecterxsuccessmsg: action.payload,
      };
    //Get operator notes data
    case ERX_OPERATOR_NOTES:
      return {
        ...state,
        erxOperatorNotes: action.payload,
      };
    //get image data
    case ERX_IMG_ARR:
      return {
        ...state,
        erximgdatas: action.payload,
      };
    //get erx with img data
    case ERX_IMG_OCR:
      return {
        ...state,
        erximgocrdatas: action.payload,
      };
    //Assign successmsg
    case MEDICINE_SUCCESS_MSG:
      return {
        ...state,
        erxmedicinesuccessmsg: action.payload,
      };
    //Assign dashboard pending erx data
    case DASHBOARD_ERX_DATA:
      return {
        ...state,
        dashboarderxData: action.payload,
      };
    case DASHBOARD_RX_DETAILS:
      return {
        ...state,
        dashboarderxDetails: action.payload,
      };
    case DASHBOARD_ERX_DOC_OBSV:
      return {
        ...state,
        dashboarderxDocobsv: action.payload,
      };
    case DASHBOARD_ERX_LAB_OBSV:
      return {
        ...state,
        dashboarderxLabobsv: action.payload,
      };
    case DASHBOARD_ERX_PATIENT_VITAL:
      return {
        ...state,
        dashboarderxPatientVital: action.payload,
      };
    case DASHBOARD_ERX_DOCPROCEDURE:
      return {
        ...state,
        dashboarderxDocProcedure: action.payload,
      };

    case DASHBOARD_ERX_MEDICINE_DETAIL:
      return {
        ...state,
        dashboarderxmedicinedetail: action.payload,
      };
    //bind doctor edit load data
    case GET_DOCTOR_EDITDATA:
      return {
        ...state,
        doctorDetails: action.payload,
      };
    //Insert prescription
    case DASHBOARD_INSERT_PRESCRIPTION:
      return {
        ...state,
        dashboardinsertprescriptions: action.payload,
      };
    //Assign dashboard inprogress data
    case DASHBOARD_INPROGRESSS_DATA:
      return {
        ...state,
        dashboardInprogressList: action.payload,
      };
    //Assign inprogress count
    case DASHBOARD_INPROGRESSS_COUNT:
      return {
        ...state,
        inprogresCount: action.payload,
      };
    //doctor success msg
    case DOCTOR_SUCCESS_MSG:
      return {
        ...state,
        doctorsuccessmsg: action.payload,
      };

    //doctor update msg
    case DOCTOR_UPDATE_MSG:
      return {
        ...state,
        doctorupdatemsg: action.payload,
      };

    //REJECT_ERX_SUCCESS_MSG
    case REJECT_ERX_SUCCESS_MSG:
      return {
        ...state,
        rejectsuccessmsg: action.payload,
      };

    case DASHBOARD_COMPLETED_DATA:
      return {
        ...state,
        dashboardCompletedList: action.payload,
      };
    case DASHBOARD_COMPLETED_COUNT:
      return {
        ...state,
        dashboardCompletedcountList: action.payload,
      };
    case UNIT_DATA:
      return {
        ...state,
        unitdata: action.payload,
      };

    //Assign delete medicine details
    case DELETE_MEDCINE_SUCCESS_MSG:
      return {
        ...state,
        deletemedicinesuccessmsg: action.payload,
      };
    case SET_DOCTOR_ID_NAME:
      return {
        ...state,
        doctoridname: action.payload,
      };

    //for specialization master array
    case DOCTOR_SPECIALIZATION:
      return {
        ...state,
        specializations: action.payload,
      };
    case DOCTOR_SUBSPECIALIZATION:
      return {
        ...state,
        subspecializations: action.payload,
      };
    case DOCTOR_QUALIFICATION:
      return {
        ...state,
        qualifications: action.payload,
      };

    // master list
    case DEPARTMENT_MASTER:
      return {
        ...state,
        departmentMaster: action.payload,
      };
    case STATE_MASTER:
      return {
        ...state,
        stateMaster: action.payload,
      };
    case DOCTOR_SEARCH_ARRAY:
      return {
        ...state,
        doctorSearchDatas: action.payload,
      };

    case DOCTOR_SEARCH_DATA:
      return {
        ...state,
        searchableDoctor: action.payload,
      };
    //Get title list
    case resulttitle:
      return {
        ...state,
        resulttitle: action.payload,
      };
    //Get blood sugar list
    case bloodsugarList:
      return {
        ...state,
        bloodsugarList: action.payload,
      };
    case CHANGE_MOBILENO_RES:
      return {
        ...state,
        changemobilenores: action.payload,
      };
    //Get grx medicine details
    case DASHBOARD_GRX_MEDICINE_DETAIL:
      return {
        ...state,
        dashboardgrxmedicinedetail: action.payload,
      };
    case REPORT_DETAILS:
      return {
        ...state,
        reportDetails: action.payload,
      };
    case INVESTIGATION_CATEGORY_DATA:
      return {
        ...state,
        investigationCategoryData: action.payload,
      };
    case TAG_CATEGORY_DATA:
      return {
        ...state,
        tagCategoryData: action.payload,
      };
    case PATIENT_LISTS:
      return {
        ...state,
        patientListdata: action.payload,
      };
    case SEARCH_REPORTS_DATA:
      return {
        ...state,
        searchReportsData: action.payload,
      };

    case SEARCH_REPORTS_DATA_COUNT:
      return {
        ...state,
        searchReportsDataCounts: action.payload,
      };
    case ERX_HTML_NO_DATA:
      return {
        ...state,
        erxhtmlnodata: action.payload,
      };
    case ERX_HTML_DATA:
      return {
        ...state,
        erxhtmldata: action.payload,
      };
    case BUCKET_CAPTCHA:
      return {
        ...state,
        bucketCaptcha: action.payload,
      };

    case APPOINTMENT:
      return {
        ...state,
        appointment: action.payload,
      };

    case APPOINTMENT_PATIENTS:
      return {
        ...state,
        appointmentPatients: action.payload,
      };
    case INVESTIGATIONS_LIST:
      return {
        ...state,
        inves_list: action.payload,
      };
    case REPORT_PATIENT_LIST:
      return {
        ...state,
        patient_list: action.payload,
      };
    case REPORT_SUCCESS:
      return {
        ...state,
        report_success: action.payload,
      };
    case REPORT_DELETE_SUCCESS:
      return {
        ...state,
        report_delete_success: action.payload,
      };
    case ONE_MG_DATA:
      return {
        ...state,
        oneMgData: action.payload,
      };
    case OTP_TIME:
      return {
        ...state,
        otptime: action.payload,
      };
    default:
      return state;
  }
};
export default dashboardReducer;
