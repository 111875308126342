/************
 * Developed by : Shiva Software Solutions
 * Date : 15-06-2021
 * Descriptions : Route navigation for inner modules
 ************/

import {authRole} from '../shared/constants/AppConst';
// const localRoleId = localStorage.getItem('roleId');

const routesConfig = [
  {
    id: 'dashbaord',
    title: 'Dashboard',
    messageId: 'sidebar.dashboard',
    type: 'single',
    icon: 'dashboard',
    auth: authRole.dashboardRoles,
    url: '/dashboard',
  },
  {
    id: 'reports',
    title: 'Reports',
    messageId: 'sidebar.reports',
    type: 'single',
    icon: 'receipt_long',
    auth: authRole.adminRoles,
    url: '/reports',
  },
  {
    id: 'vitals',
    title: 'Vitals',
    messageId: 'sidebar.vitals',
    type: 'single',
    icon: 'masks',
    auth: authRole.adminRoles,
    url: '/covidVitals',
  },
  {
    id: 'appointments',
    title: 'appointments',
    messageId: 'sidebar.appointments',
    type: 'single',
    icon: 'event_note',
    auth: authRole.adminRoles,
    url: '/appointments',
    // color: '#f4bd00',
  },
  {
    id: 'mappingpatients',
    title: 'mappingpatients',
    messageId: 'sidebar.mappingpatients',
    type: 'single',
    icon: 'call_merge',
    auth: authRole.adminRoles,
    url: '/mergingPatient',
  },
];
export default routesConfig;
