/************
 * Developed by : Shiva Software Solutions
 * Date : 31-10-2022
 * Descriptions : Login AND Signup Functionality
 ************/
import React from 'react';
import {
  FETCH_START,
  FETCH_ERROR,
  MAPPING_PATIENT_LIST,
  DROP_PATIENT_LIST,
  DROP_PATIENT_RELATION,
  MAPPING_SUCCESS,
  PATIENT_RELATION_SUCCESS,OTP_SIGNUP_ERROR
} from '../../shared/constants/ActionTypes';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {fetchError, onWriteCloudWatchLog} from './Common';
import IntlMessages from '../../@crema/utility/IntlMessages';
//AWS cloud watch name
var streamname = 'Redux_Actions';
var filename = 'MappingPatient.js';

// Get mapping patient
export const onGetMappingPatient = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/patMgmt/getmergingPatientListsjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/patMgmt/getmergingPatientLists', value)
              .then((data) => {
                if (data.data.status === 200) {
                  if (data.data.body?.patientNameLists?.length > 0) {
                    dispatch({
                      type: MAPPING_PATIENT_LIST,
                      payload: data.data.body.patientNameLists,
                    });
                    dispatch({
                      type: DROP_PATIENT_LIST,
                      payload: data.data.body.pat_drop_List,
                    });
                    dispatch({
                      type: DROP_PATIENT_RELATION,
                      payload: data.data.body.relation,
                    });
                  } else {
                    dispatch({
                      type: MAPPING_PATIENT_LIST,
                      payload: [],
                    });
                    dispatch({
                      type: DROP_PATIENT_LIST,
                      payload: [],
                    });
                    dispatch({
                      type: DROP_PATIENT_RELATION,
                      payload: [],
                    });
                  }
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetMappingPatient',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetMappingPatient',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetMappingPatient',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetMappingPatient',
          '0',
          err,
        ),
      );
    }
  };
};

// Get Insert mapping Patients
export const insertMergingPatients = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/patMgmt/insertPatientMergingjwt', value)
        .then((data) => {
          try {
            console.log(data.data, 'OTP_SIGNUP_ERROR')
            if (data.data.message && data.data.message.includes('Error')) {
              dispatch({
                type: OTP_SIGNUP_ERROR,
                payload: data.data.message.split(':')[2],
              });
              return;
            }
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/patMgmt/insertPatientMerging', value)
              .then((data) => {
                if (data.data.status === 200) {
                  if (data.data.message) {
                    dispatch({
                      type: MAPPING_SUCCESS,
                      payload: data.data.message,
                    });
                  } else {
                    dispatch({
                      type: MAPPING_SUCCESS,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetMappingPatient',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetMappingPatient',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetMappingPatient',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetMappingPatient',
          '0',
          err,
        ),
      );
    }
  };
};

// updatePatientRelation
export const updatePatientRelation = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/patMgmt/updatePatientRelationJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/patMgmt/updatePatientRelation', value)
              .then((data) => {
                if (data.data.status === 200) {
                  if (data.data.message) {
                    dispatch({
                      type: PATIENT_RELATION_SUCCESS,
                      payload: data.data.message,
                    });
                  } else {
                    dispatch({
                      type: PATIENT_RELATION_SUCCESS,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetMappingPatient',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetMappingPatient',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetMappingPatient',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetMappingPatient',
          '0',
          err,
        ),
      );
    }
  };
};
